:root {
  --flat-dark-earth: #7B6A4C;
  --tan: #D2B48C;
  --desert-sand: #EDC9A5;
  --olive-drab: #4B4C39;
  --lighter-olive-drab: #6B6C5C;
  --dark-khaki: #BDB76B;
}
@import url('https://fonts.googleapis.com/css2?family=Staatliches&display=swap');

*{
  font-family: Staatliches;
  margin: 0;
  padding:0;
}

body {
  margin: 0;
  padding: 0;
  align-items: center;
  justify-content: center;
  background-color: var(--flat-dark-earth);
}

