body {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: var(--flat-dark-earth);
}
/* All mobile screens */
@media only screen and (max-width: 600px) {
  header {
    width: 100vw;
    margin-top: 6vh;
  }
  .large_font {
    font-size: 69px;
    margin-top: 25vh;
  }
  .medium_font {
    font-size: 30px;
  }
  .small_font {
    font-size: 20px;
  }
}

@media screen and (min-width: 601px) {
  .large_font {
    font-size: 100px;
    margin-top: 25vh;
  }
  .medium_font {
    font-size: 69px;
  }
  .small_font {
    font-size: 30px;
  }
}

header {
  background-color: var(--olive-drab);
  color: white;
  text-align: center;
  padding-top: 1.5rem;
  margin-top: 3vh;
  width: 100vw;
}

.content {
  text-align: center;
}

p {
  margin-left: 10vw;
  margin-right: 10vw;
  margin-top: 3vh;
  margin-bottom: 3vh;
}

.holster_image {
  height: 50vh;
}

.cta-button {
  background-color: var(--olive-drab);
  color: black;
  padding: 10px 20px;
  margin: 20px;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.cta-button:hover {
  background-color: var(--lighter-olive-drab);
}
